.banner-karir{
background-color: #204463;
  width: 100%;
  height: 765px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.banner-karir h1{
    color: #fff;
    font-size: 48px;
    font-weight: bold;
    text-align: center;
}
.banner-karir span{
    color: #00BF63;
}
.banner-karir p{
    margin-top: 20px;
    color: #fff;
    font-size: 24;
    text-align: center;
}
.daftar-pekerjaan-teks{
    text-align: center;
    padding: 30px;
    color:#0F0F0F;
    /* font-weight: bold; */
}
.daftar-pekerjaan-teks h1{
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 30px;
}
.career-cards {
    display: flex;
    justify-content: center;
  }
  
  .career-card {
    margin: 10px;
    padding: 20px;
    border: 1px solid #ccc;
    text-align: center;
  }

  /* Tambahkan class untuk styling grid */
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    /* Menggunakan auto-fill untuk mengisi kolom, minmax untuk batas lebar kolom */
    gap: 20px;
    /* Jarak antar item */
    margin: 0 auto;
    /* Menengahkan grid */
    max-width: 1200px;
    /* Menambahkan batas lebar maksimum */
    padding: 0 20px;
    /* Memberikan padding agar tampilan tetap baik */
}

/* Tambahkan class untuk styling setiap item dalam grid */
.grid-item {
    box-sizing: border-box;
    /* Mencegah pemenggalan lebar dari padding atau border */
    width: 100%;
    /* Mengisi lebar konten sepenuhnya */
}

/* Media query untuk menyesuaikan grid pada layar kecil */
@media (max-width: 1200px) {
    .grid-container {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        /* Menyesuaikan pada layar yang lebih kecil */
    }
}

@media (max-width: 800px) {
    .grid-container {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        /* Menyesuaikan pada layar kecil lebih lanjut */
    }
}


/* Sesuaikan tata letak mobile jika diperlukan */
@media (max-width: 600px) {
    /* Tambahkan class untuk styling grid */
    .grid-container {
        display: flex;
        flex-direction: column;
        /* Stack items vertically */
        justify-content: center;
        /* Center vertically */
        align-items: center;
        /* Center horizontally */
        margin: 0 auto;
        /* Center horizontally within the viewport */
        max-width: 1200px;
        /* Set maximum width */
        padding: 20px;
        /* Add padding for spacing */
    }

    /* Tambahkan class untuk styling setiap item dalam grid */
    .grid-item {
        box-sizing: border-box;
        /* Mencegah pemenggalan lebar dari padding atau border */
        width: 100%;
        /* Mengisi lebar konten sepenuhnya */
        text-align: center;
        margin-bottom: 20px;
        /* Add margin for spacing between items */
    }


}

/* fonts */
.poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
  }
  
  .poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  
  .poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
  }
  
  .poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
  }
  
  .poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
  }
  
  .poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
  }
  
  

