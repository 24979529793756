/* styles.css */
.footer {
    background-color: var(--bg-color);
    padding: 50px;
    text-align: center;
}

.social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Jarak antara ikon */
    margin: 15px;
}

.social-icons svg {
    color: var(--bg-color);
    font-size: 40px;
    background-color: white;
    position: relative;
}
/* .circleClasses{
    border-radius: 50%;
} */

/* Tambahkan garis putih di sebelah kiri dan kanan ikon */
.social-icons svg::before,
.social-icons svg::after {
    content: '';
    position: absolute;
    background-color: white;
    height: 2px;
    width: 20px; /* Sesuaikan panjang garis putih */
    top: 50%;
    transform: translateX(-50%);
}

.social-icons svg::before {
    left: -35px; /* Geser garis ke sebelah kiri ikon */
}

.social-icons svg::after {
    right: -35px; /* Geser garis ke sebelah kanan ikon */
}

.footer p {
    color: white;
}

.separator {
    width: 800px;
    height: 1px;
    background-color: white !important;
    border: none;
    opacity: 100%;
}
.social-icon {
    background: #ffffff;
    border-radius: 50%;
    padding: 10px;
  }

@media (max-width: 767px) {
    .social-icons svg {
        color: var(--bg-color);
        font-size: 20px;
        background-color: white;
        position: relative;
    }
    .social-icon {
        background: #ffffff;
        border-radius: 50%;
        padding: 8px;
      }
      .separator {
        width: 500px;
        height: 1px;
        background-color: white !important;
        border: none;
        opacity: 100%;
    }
    .footer {
        background-color: var(--bg-color);
        padding: 10px;
        text-align: center;
    }
}
