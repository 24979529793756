.img-banner {
    background-size: cover;
    width: 100%;
}

.banner-fg-div {
    position: relative;
    color: white;
}

.banner-fg-text {
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
}

.our-members-title {
    color: #000;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    /* 166.667% */
    margin-top: 100px;
    /* Menambahkan jarak di atas */
    margin-bottom: 100px;
    /* Menambahkan jarak di bawah */
}

/* Tambahkan class untuk styling grid */
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    /* Menggunakan auto-fill untuk mengisi kolom, minmax untuk batas lebar kolom */
    gap: 20px;
    /* Jarak antar item */
    margin: 0 auto;
    /* Menengahkan grid */
    max-width: 1200px;
    /* Menambahkan batas lebar maksimum */
    padding: 0 20px;
    /* Memberikan padding agar tampilan tetap baik */
}

/* Tambahkan class untuk styling setiap item dalam grid */
.grid-item {
    box-sizing: border-box;
    /* Mencegah pemenggalan lebar dari padding atau border */
    width: 100%;
    /* Mengisi lebar konten sepenuhnya */
}

/* Media query untuk menyesuaikan grid pada layar kecil */
@media (max-width: 1200px) {
    .grid-container {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        /* Menyesuaikan pada layar yang lebih kecil */
    }
}

@media (max-width: 800px) {
    .grid-container {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        /* Menyesuaikan pada layar kecil lebih lanjut */
    }
}

/* Sesuaikan tata letak mobile jika diperlukan */
@media (max-width: 600px) {
    .banner-fg-text {
        font-size: 18px;
        /* Contoh: Menyesuaikan ukuran teks di layar kecil */
        left: 50%;
        transform: translateX(-50%);
        /* Menengahkan teks pada layar kecil */
    }

    .our-members-title {
        margin-top: 50px;
        /* Mengurangi margin di layar kecil */
        margin-bottom: 50px;
        /* Mengurangi margin di layar kecil */
    }

    .banner-fg-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .banner-fg-text h1 {
        font-size: 20px;
    }

    /* Tambahkan class untuk styling grid */
    .grid-container {
        display: flex;
        flex-direction: column;
        /* Stack items vertically */
        justify-content: center;
        /* Center vertically */
        align-items: center;
        /* Center horizontally */
        margin: 0 auto;
        /* Center horizontally within the viewport */
        max-width: 1200px;
        /* Set maximum width */
        padding: 20px;
        /* Add padding for spacing */
    }

    /* Tambahkan class untuk styling setiap item dalam grid */
    .grid-item {
        box-sizing: border-box;
        /* Mencegah pemenggalan lebar dari padding atau border */
        width: 100%;
        /* Mengisi lebar konten sepenuhnya */
        text-align: center;
        margin-bottom: 20px;
        /* Add margin for spacing between items */
    }


}

/* fonts */
.poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
  }
  
  .poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  
  .poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
  }
  
  .poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
  }
  
  .poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
  }
  
  .poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
  }