/* Header.css */
.navbar {
    background-color: white;
  }
  
  .navbar-brand {
    font-family: "Poppins", sans-serif;
    
    color: black;
    
  }
  
  .navbar-nav .nav-link {
    font-family: "Poppins", sans-serif;
    color: black;
  }
  