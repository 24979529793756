/* styles.css */
.image-banner {
    position: relative;
    color: white;
}

.banner-text {
    position: absolute;
    color: white;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1); 
}


.banner-text h1 {
    font-size: 70px;
}

.about-us {
    padding: 100px;
}

.about-us-text {
    margin-top: 55px;
}

.banner-keunggulan {
    position: relative;
    color: white;
}

.banner-keunggulan-text {
    position: absolute;
    color: white;
    top: 210%;
    left: 75%;
    transform: translate(-80%, -50%);
}

.white-box {
    width: 655px;
    height: 455px;
    position: absolute;
    color: white;
    top: 215%;
    left: 22%;
    transform: translate(-80%, -50%);
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.white-box2 {
    width: 300px;
    height: 150px;
    position: absolute;
    color: white;
    top: 205%;
    left: 40%;
    transform: translate(-80%, -50%);
    background-color: white;
    border: 1px black;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.green-box {
    width: 300px;
    height: 150px;
    position: absolute;
    color: #01C12C;
    top: 230%;
    left: 40%;
    transform: translate(-80%, -50%);
    background-color: lightgreen;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.row-container {
    display: flex;
    justify-content: space-between;
}

.child {
    box-sizing: border-box;
}

.text-overlay {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.text-overlay-black {
    color: #000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}
.portofolio{
    position: relative;
    padding: 10px;
}
.owl-theme{
    position: relative;
}
.carousel-container {
    position: relative;
    z-index: 1; /* Pastikan carousel tetap di atas popup */
}
.owl-carousel{
    display: flex !important;
    flex-direction: row;
    justify-content: center;
}
.owl-wrapper{
    margin: 0 auto;
}

.popup-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2; /* Pastikan popup selalu di atas carousel */
    pointer-events: none; /* Menyembunyikan elemen dari interaksi pengguna */
}
.portofolio-title {
    padding: 50px;
}

.portofolio-title h1 {
    text-align: center;
}

.item.porto-item {
    position: relative;
}
.img-large{
    position: absolute;
    left: -9999px;
}
.item.porto-item:hover .img-large{
   left: 200px
}
.porto-desc {
    background-color: var(--bg-color);
    color: white;
    margin-right: 0;
    padding-right: 0;
    margin-left: 0;
    padding-left: 0;
    width: 400px;
    height: 400px;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    
}



.text-overlay-porto {
    position: absolute;
    bottom: 20%;
    right: 0;
    /* transform: translate(-50%, -50%); */
    background-color: rgba(32, 68, 99, 0.8);
    color: white;
    /* padding: 10px; */
    width: 100%;
    align-items: center;
    text-align: center;
}

.text-overlay-porto p,
.text-overlay-porto h1 {
    margin: 0;
    align-self: center;
    justify-content: center;
    text-align: center;
}

.read-more-link {
    color: blue;
    /* Ganti warna tautan sesuai keinginan */
    text-decoration: underline;
    /* Menambahkan garis bawah pada tautan */
}


.testimoni-title {
    padding: 50px;
}

.testimoni-title h1 {
    text-align: center;
}

.visi-misi {
    display: flex;
    justify-content: space-between;
    padding: 100px;
    background-color: var(--bg-color);
    text-align: center;
}

.row {
    display: flex;
    width: 100%;
}

.visi,
.misi {
    width: 48%;
    /* Atur lebar sesuai kebutuhan, atau gunakan media queries untuk responsif */
    color: #fff;

}

.news {
    padding: 50px;
}

.news-title {
    padding: 50px;
}

.news h1 {
    text-align: center;
}


.news-item {
    display: flex;
    justify-content: space-around;
}

.news-item-1,
.news-item-2,
.news-item-3 {
    position: relative;
    text-align: left;
}

.news-item-1 img,
.news-item-2 img,
.news-item-3 img {
    display: block;
}

.news-item-1 h1,
.news-item-2 h1,
.news-item-3 h1 {
    position: absolute;
    bottom: 100px;
    /* Atur jarak dari bawah gambar sesuai kebutuhan */
    left: 20px;
    right: 0;
    margin: auto;
    z-index: 1;
    font-size: 30px;
    /* Atur font size h1 */
    text-align: left;
}

.news-item-2 h1,
.news-item-2 p {
    color: #fff;
}

.news-item-1 p,
.news-item-2 p,
.news-item-3 p {
    position: absolute;
    bottom: 60px;
    left: 20px;
    right: 20px;
    margin: auto;
    z-index: 1;
    font-size: 28px;
    /* Atur font size p */
    text-align: left;
}

.affiliate-company {
    text-align: center;
}

.affiliate-company-title {
    padding: 50px;
}

.affiliate-company-title h1 {
    text-align: center;
}

.affiliate-company-img img {
    align-self: center;
    margin: 0 auto;
}

.faq {
    margin: 20px;
    padding: 50px;
}

.Collapsible {
    background-color: white;
    border-bottom: 1px solid black;
}

.Collapsible__contentInner {
    padding: 10px;
    border: 1px solid lightgrey;
    border-top: 0;

    p {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}


.Collapsible__trigger {
    display: block;
    font-weight: bold;
    text-decoration: none;
    position: relative;
    border: 1px solid white;
    padding: 10px;
    background: white;
    color: black;
}
.waButton{
    opacity: 0.5; /* Opacity 50% saat tidak di-hover */
    transition: opacity 0.3s ease; /* Efek transisi untuk perubahan opacity */
}
.waButton:hover{
    opacity: 1;
}
/* fonts */
.poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
  }
  
  .poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  
  .poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
  }
  
  .poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
  }
  
  .poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
  }
  
  .poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
  }
  



/* Media query untuk menyesuaikan pada layar kecil */
@media (max-width: 1200px) {
    .banner-keunggulan-text {
        position: absolute;
        color: white;
        top: 400%;
        left: 80%;
        transform: translate(-80%, -50%);
    }
}

@media (max-width: 800px) {
    .banner-keunggulan-text {
        position: absolute;
        color: white;
        top: 350%;
        left: 170%;
        transform: translate(-80%, -50%);
    }
}

/* Sesuaikan tata letak mobile jika diperlukan */
@media (max-width: 767px) {
    .banner-text {
        position: absolute;
        color: white;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .banner-keunggulan {
        position: relative;
        color: white;
    }

    .banner-keunggulan-text {
        position: absolute;
        color: white;
        top: 118%;
        left: 70%;
        transform: translate(-50%, -50%);
    }

    .banner-keunggulan-text h1 {
        font-size: 13px;
    }

    .white-box {
        width: 200px;
        height: 100px;
        position: absolute;
        color: white;
        top: 118%;
        left: 25%;
        transform: translate(-80%, -50%);
        background-color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .white-box2 {
        width: 75px;
        height: 50px;
        position: absolute;
        color: #ffffff;
        top: 115%;
        left: 40%;
        transform: translate(-80%, -50%);
        background-color: white;
        border: 1px black;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    .green-box {
        width: 75px;
        height: 50px;
        position: absolute;
        color: #01C12C;
        top: 121%;
        left: 40%;
        transform: translate(-80%, -50%);
        background-color: lightgreen;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .row-container {
        display: flex;
        justify-content: space-between;
    }

    .child {
        box-sizing: border-box;
    }

    .text-overlay {
        color: white;
        position: absolute;
        top: 70%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .text-overlay-black {
        color: #000;
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .text-overlay h1 {
        font-size: 9px;
    }

    .text-overlay-black h1 {
        font-size: 9px;
    }

    .text-overlay p {
        font-size: 8px;
    }

    .text-overlay-black p {
        font-size: 8px;
    }

    .affiliate-company-img {
        width: 300px;
    }

    .center {
        text-align: center;
        align-items: center;
        align-self: center;
        margin: 0 auto;

    }

}